import clsx from 'clsx'

interface IInterlocutorItemProps {
  firstname: string
  position: 'project_manager' | 'devops' | 'product_owners' | ''
  status: string
  image: string
}

interface IPosition {
  [key: string]: string
}

const positionList: IPosition = {
  project_manager: 'Directeur projet',
  product_owners: 'Product Owner',
  devops: 'DevOps',
  tech_leads: 'Tech Lead',
  designers: 'Designer',
}

const ONLINE = 'En ligne'

const InterlocutorItem: React.FC<IInterlocutorItemProps> = ({
  firstname,
  position,
  status,
  image,
}) => {
  return (
    <div className="flex justify-between items-center">
      <div className="inline-flex gap-4">
        <figure className="w-10 h-10 overflow-hidden rounded-full border border-neutral-dark">
          <img
            src={image}
            alt={firstname}
            className="max-w-full object-cover"
          />
        </figure>
        <div className="inline-flex flex-col">
          <span className="text-primary-dark font-semibold text-sm">
            {firstname}
          </span>
          <span className="text-neutral-darker text-xs">
            {positionList[position]}
          </span>
        </div>
      </div>
      <div
        className={clsx(
          'rounded-full px-2 py-[2px]',
          status === ONLINE ? 'bg-success/10' : 'bg-neutral-light'
        )}
      >
        <div className="inline-flex items-center gap-2">
          <span
            className={clsx(
              'w-2 h-2 rounded-full block',
              status === ONLINE ? 'bg-success' : 'bg-neutral-darker'
            )}
          ></span>
          <span className="text-xs ">{status}</span>
        </div>
      </div>
    </div>
  )
}

export default InterlocutorItem
